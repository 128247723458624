import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <h1>A React app</h1>
        <p>Edit <code>src/App.js</code>, save and reload.</p>
      </div>
    );
  }
}

export default App;
