import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';


const firebaseConfig = {
  apiKey: "AIzaSyBY3txEL6wEzmiYTuJJiRHfKOEakWhOH9A",
  authDomain: "eadmin-55ab1.firebaseapp.com",
  projectId: "eadmin-55ab1",
  storageBucket: "eadmin-55ab1.appspot.com",
  messagingSenderId: "788657698922",
  appId: "1:788657698922:web:3441ed6231e3b86ca83213",
  measurementId: "G-GXVYF45BKL"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const request = require('request') 
const cron = require('node-cron');

function logMessage() {
  console.log('Cron job executed at:', new Date().toLocaleString());
  }

  cron.schedule('* * * * *', () => {
    logMessage();
    });

const db = getFirestore(app);

const curDate = Date.now();
function getDateWithoutTime(date) {
  const dateWithoutTime = new Date(date);

  dateWithoutTime.setHours(0, 0, 0, 0);

  const year = dateWithoutTime.getFullYear();
  const month = String(dateWithoutTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateWithoutTime.getDate() + 1).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function editFirebaseDates(date) {
  const dateWithoutTime = new Date(date);
  dateWithoutTime.setHours(0, 0, 0, 0);

  const year = dateWithoutTime.getFullYear();
  const month = String(dateWithoutTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateWithoutTime.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Get a list of cities from your database
async function sendNotification(db) {
  const res = [];
  const mainCollection = collection(db, 'green_module_area');
  const mainSnapshot = await getDocs(mainCollection);

 mainSnapshot.forEach(async (doc) => {
  const mainCollection2 = collection(db, 'green_module_area', doc.id, 'polygones');
  const mainSnapshot2 = await getDocs(mainCollection2);
  mainSnapshot2.forEach(async (doc2) => {
    const mainCollection3 = collection(db, 'green_module_area', doc.id, 'polygones', doc2.id, 'calendar');
    const mainSnapshot3 = await getDocs(mainCollection3);
    mainSnapshot3.forEach(async (doc3) => {
      const myDate = doc3.data().date.toDate();
      const fixedDate = myDate;
      const actualDocDate = editFirebaseDates(fixedDate);
      console.log('docDate: ', actualDocDate);
      console.log('curDate: ', getDateWithoutTime(curDate));
      if (actualDocDate == getDateWithoutTime(curDate)) {
        console.log("----> ugyanazon datumok ", doc3.id);
        const m = {
          title: doc3.data().waste_name,
          title_ro: doc3.data().waste_name_ro,
          txt: doc3.data().notification_text[0]["insert"],
          txt_ro: doc3.data().notification_text_ro[0]["insert"],
          topic: doc2.id,
        };
        res.push(m);
      }
    });
  });
});

let title = "";

let title_ro = "";
let desc = "";

let desc_ro = "";

let notification;
let notification_ro;
let yourServerKey;

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substring(0, index) +
    replacement +
    this.substring(index + replacement.length)
  );
};

const sample = async () => {
  console.log("waiting...");
  await delay(10000);
  console.log("waiting...");
  if (res.length != 0) {
    if (res.length > 1) {
      const results = res.reduce((results, org) => {
        (results[org.topic] = results[org.topic] || []).push(org);
        return results;
      }, {});

      const unique = [...new Set(res.map((item) => item.topic))];

      for (let c = 0; c < unique.length; c++) {
        if (results[unique[c]].length == 1) {
          title =
            "Holnap a kovetkezo hulladekot szallitjuk: " +
            results[unique[c]][0].title;
          title_ro =
            "RO Holnap a kovetkezo hulladekoy szallitjuk: " +
            results[unique[c]][0].title_ro;
          desc = results[unique[c]][0].txt;
          desc_ro = results[unique[c]][0].txt_ro;

          notification = {
            title: title,
            description: desc,
            topic: results[unique[c]][0].topic,
          };
          notification_ro = {
            title: title_ro,
            description: desc_ro,
            topic: results[unique[c]][0].topic,
          };

          // send function

          var topic1 = results[unique[c]][0].topic;
          yourServerKey =
            "AAAAt5-peGo:APA91bHuu2RfnkjjHcwtJCOi_ltbyhGj1fbCEbHPPkRLvf8-UStayEnnyrVHB3RdbG1v4mcNiR-y9wsrR0ZIOXhL1FmTFQbMs7TzCaGXKGGIytPSP_fpDXWcGEej7y1gCxsvQeRwXDc0";

          request({
            url: "https://fcm.googleapis.com/fcm/send",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": ["key", yourServerKey].join("="),
            },
            json: {
              to: "/topics/" + topic1,
              notification: {
                title: "eAdmin Mezőpanit/eAdmin Pănet",
                body: title + "/" + title_ro,
              },
              priority: "high",
              data: {
                click_action: "FLUTTER_NOTIFICATION_CLICK",
                id: "1",
                sound: "default",
                type: "greenmodule",
                screen: "0",
              },
            },
          });

          console.log('Notification sent!\n', title);
        } else {
          /* eslint-disable no-unused-vars */
          let mixedTitle = "";
          let mixedTitleRo = "";
          let localDesc = "";
          let localDescRo = "";
          for (var b in results[unique[c]]) {
              mixedTitle = results[unique[c]][b].title + ", " + mixedTitle;
              mixedTitleRo =
                results[unique[c]][b].title_ro + ", " + mixedTitleRo;
              localDesc = "";
              localDescRo = "";
          
          }


          mixedTitle = mixedTitle.slice(0, -1);
          mixedTitle = mixedTitle.slice(0, -1);

          mixedTitleRo = mixedTitleRo.slice(0, -1);
          mixedTitleRo = mixedTitleRo.slice(0, -1);

          console.log('mixed title: ', mixedTitle);


          notification = {
            title: "Holnap a kovetkezo hulladekokat szallitjuk: " + mixedTitle,
            description: localDesc,
            topic: results[unique[c]][b].topic,
          };
          notification_ro = {
            title:
              "RO Holnap a kovetkezo hulladekokat szallitjuk: " + mixedTitleRo,
            description: localDescRo,
            topic: results[unique[c]][b].topic,
          };



          var topic2 = results[unique[c]][b].topic;
          yourServerKey =
            "AAAAt5-peGo:APA91bHuu2RfnkjjHcwtJCOi_ltbyhGj1fbCEbHPPkRLvf8-UStayEnnyrVHB3RdbG1v4mcNiR-y9wsrR0ZIOXhL1FmTFQbMs7TzCaGXKGGIytPSP_fpDXWcGEej7y1gCxsvQeRwXDc0";

          request({
            url: "https://fcm.googleapis.com/fcm/send",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": ["key", yourServerKey].join("="),
            },
            json: {
              to: "/topics/" + topic2,
              notification: {
                title: "eAdmin Mezőpanit/eAdmin Pănet",
                body: notification["title"] + "/" + notification_ro["title"],
              },
              priority: "high",
              data: {
                click_action: "FLUTTER_NOTIFICATION_CLICK",
                id: "1",
                sound: "default",
                type: "greenmodule",
                screen: "0",
              },
            },
          });
          console.log('Notification sent!\n', notification["title"]);

        }
      }
    } else {
      // send notification - if list length = 1
      desc = res[0]["txt"];
      desc_ro = res[0]["txt_ro"];

      title =
        "Holnap " +
        "(" +
        getDateWithoutTime(curDate) +
        ")" +
        " a kovetkezo hulladekot szallitjuk: " + res[0]["title"] +"\n"+desc;
      title_ro = "RO Holnap " +
        "(" +
        getDateWithoutTime(curDate) +
        ")" +
        " a kovetkezo hulladekot szallitjuk: " +
        res[0]["title_ro"] +"\n"+desc_ro;
   

      var topic3 = res[0]["topic"];
      yourServerKey =
        "AAAAt5-peGo:APA91bHuu2RfnkjjHcwtJCOi_ltbyhGj1fbCEbHPPkRLvf8-UStayEnnyrVHB3RdbG1v4mcNiR-y9wsrR0ZIOXhL1FmTFQbMs7TzCaGXKGGIytPSP_fpDXWcGEej7y1gCxsvQeRwXDc0";

      request({
        url: "https://fcm.googleapis.com/fcm/send",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": ["key", yourServerKey].join("="),
        },
        json: {
          to: "/topics/" + topic3,
          notification: {
            title: "eAdmin Mezőpanit/eAdmin Pănet",
            body: title + "/" + title_ro,
          },
          priority: "high",
          data: {
            click_action: "FLUTTER_NOTIFICATION_CLICK",
            type: "greenmodule",
            screen: "0",
          },
        },
      });
      console.log('Notification sent!\n', title);
    }
  } else {
    console.log("NO NOTIFICATION TODAY");
  }
};
sample();

}
const method = sendNotification(db);

ReactDOM.render(<App />, document.getElementById('root'));
